import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import AdminService from "../../services/ui/admin-service";
import { Order } from "../../models/responses/CheckoutResponse";
import { VariantOption } from "../../models/data/product";
import { CartItem } from "../../services/ui/cart-service";
import CustomFormat from "../../helpers/time-helper";
import Price from "../price/price";

export default function AdminOrderPage() {

    const { id } = useParams();
    const navigation = useNavigate();

    // collection
    const [order, setOrder] = useState<Order>();

    useEffect(() => {
        if (id === undefined) return;
        AdminService.GetOrder(id, {
            success: (_: Order) => {
                setOrder(_);
            },
            error: (err: any) => {
                alert('failed to load order');
            }
        })
    }, [id])

    return <>
        {order !== undefined && order.checkoutSession !== undefined &&
            <>
                <div className="mt-4 container mx-auto px-0 mb-4">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-between py-2 mb-2">
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                <button className="bg-transparent border-0" onClick={() => navigation('/admin?tab=orders')}>
                                    <FaChevronLeft />
                                </button>

                                <h3 className="my-0">#WEB{order.orderNumber} ({order.status})</h3>
                            </div>

                            <div>
                                <button onClick={() => { }} disabled={true} className={`btn btn-danger`}>Refund</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-8 order-1 order-lg-0 bg-white rounded-lg shadow-sm pb-2">
                            <div className="mt-3">
                                <h2 className="mt-0 w-100 text-start">Order Detail</h2>
                                <div className="border rounded-lg overflow-hidden">
                                    <table className="table table-striped my-0 border-light border-bottom-0">
                                        <tbody>
                                            <tr>
                                                <td>Order Status</td>
                                                <td>{order.status}</td>
                                            </tr>
                                            <tr>
                                                <td>Order placed</td>
                                                <td>{CustomFormat(order.created)}</td>
                                            </tr>
                                            <tr>
                                                <td>Order Value</td>
                                                <td className={`${order.status !== 'accepted' ? 'text-decoration-line-through' : 'text-decoration-none'}`}><Price price={order.checkoutSession.amount} currencyCode="GBP" /></td>
                                            </tr>
                                            <tr>
                                                <td>Customer Email</td>
                                                <td>{order.checkoutSession.email}</td>
                                            </tr>
                                            <tr>
                                                <td>Customer Phone</td>
                                                <td>{order.checkoutSession.phoneNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>Payment Method</td>
                                                <td>{order.paymentMethod}</td>
                                            </tr>
                                            <tr>
                                                <td>Customer IP</td>
                                                <td>{order.checkoutSession.ip}</td>
                                            </tr>
                                            <tr>
                                                <td>Checkout Ref</td>
                                                <td>{order.checkoutSession.reference}</td>
                                            </tr>
                                            <tr>
                                                <td>Payment Auth Code</td>
                                                <td>{order.authCode}</td>
                                            </tr>
                                            <tr>
                                                <td>Checkout Message</td>
                                                <td>{order.message}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="mt-4">
                                    <p className="my-0">Products:</p>
                                    <div className="border rounded-lg overflow-hidden">
                                        <table className="table table-striped my-0 border-light border-bottom-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">SKU</th>
                                                    <th scope="col">Quantity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(JSON.parse(order.checkoutSession.items) as CartItem[]).map((c: CartItem, i: number) => {
                                                    return <tr key={`checkout-item-${i}`}>
                                                        <td>{c.variant.title}</td>
                                                        <td>{c.variant.sku}</td>
                                                        <td>{c.quantity}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <p className="my-0">Shipping to:</p>
                                    <div className="bg-light shadow-inset p-2 border rounded-3">
                                        <p className="my-0">{order.checkoutSession.shippingAddress.split(',').map((sl, i) => <span key={`shipping-line-${i}`} className="d-block">{sl}</span>)}</p>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <p className="my-0">Billed to:</p>
                                    <div className="bg-light shadow-inset p-2 border rounded-3">
                                        <p className="my-0">{order.checkoutSession.billingAddress.split(',').map((sl, i) => <span key={`shipping-line-${i}`} className="d-block">{sl}</span>)}</p>
                                    </div>
                                </div>
                            </div>


                            <details className="mt-4">
                                <summary className="py-2">Raw JSON</summary>
                                <pre>
                                    {JSON.stringify({ ...order, checkoutSession: { ...order.checkoutSession, items: JSON.parse(order.checkoutSession.items) } }, null, 2)}
                                </pre>
                            </details>
                        </div>

                        <div className="col-12 col-lg-4 order-0 order-lg-1">
                            <h2 className="mt-0 w-100 text-center">Order Summary</h2>
                            <div>
                                {(JSON.parse(order.checkoutSession.items) as CartItem[]).map((c: CartItem, i: number) => {

                                    return <div key={i} className="border mb-3 rounded-3 bg-white">
                                        <div className="p-3 d-flex align-items-stretch justify-content-start flex-column">
                                            {/* IMAGE */}
                                            <div className="pb-1">
                                                <img alt={c.variant.handle} src={c.variant.featuredMedia} className="d-block w-100 rounded-lg overflow-hidden border" />
                                            </div>
                                            {/* DETAIL */}
                                            <div className="d-flex flex-column justify-content-end align-items-bottom">
                                                <p className="fs-md-16 fs-mb-13 fw-bolder my-0">{c.variant.title} (x{c.quantity})</p>
                                                <p className="fs-md-16 fs-mb-13 fw-normal mb-1">{c.variant.sku}</p>
                                                <div className="d-sm-flex d-block align-items-center justify-content-start gap-3">
                                                    {c.variant.options.map((o: VariantOption, i: number) => {
                                                        return <div key={i} className="border rounded-3 px-1 py-1 bg-dark border-dark w-100 mb-1">
                                                            <span className="d-block w-100 text-center text-white fs-md-12">{o.viewName}</span>
                                                        </div>
                                                    })}
                                                </div>
                                                <div>
                                                    <p className="my-0">Shipped By: {c.variant.postageProvider}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>;
}
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Order } from "../../models/responses/CheckoutResponse";
import AdminService from "../../services/ui/admin-service";
import Price from "../price/price";
import CustomFormat from "../../helpers/time-helper";

export default function OrdersTabComponent() {

    const [orders, setOrders] = useState<Order[]>([]);
    const navigation = useNavigate();

    useEffect(() => {
        AdminService.GetOrders({
            success: (_: Order[]) => {
                setOrders(_);
            },
            error: (err: any) => {
                alert('failed to load orders');
            }
        })
    }, [])

    const OpenOrderHandle = useCallback((order: Order) => {
        navigation(`/admin/orders/view/${order.id}`)
    }, [navigation])

    return <>
        <div className="d-flex align-items-center justify-content-between py-2 mb-2">
            <h3 className="my-0">Orders</h3>
            <div>
                {/* <button className="btn btn-dark" onClick={() => AddNewProductHandle()}>Add Product</button> */}
            </div>
        </div>
        <table className="table rounded-3 overflow-hidden">
            <thead className="bg-secondary text-white">
                <tr>
                    <th>Order Number</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th className="text-center">Date Created</th>
                    <th>Status</th>
                    <th>Price</th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order: Order, key: number) => {
                    return <tr key={key} className={`interact-hover ${order.status === 'accepted' ? 'bg-success bg-gradient text-dark' : 'bg-light'}`} onClick={() => OpenOrderHandle(order)}>
                        <td className="py-2 align-middle">
                            <div className="d-flex align-items-center justify-content-start gap-2">
                                <p className="my-0">{order.orderNumber}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-start gap-2">
                                <p className="my-0 w-100">{order.checkoutSession.name}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-start gap-2">
                                <p className="my-0 w-100">{order.checkoutSession.shippingZip}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-center">
                                <p className="text-center my-0 w-100">{CustomFormat(order.created)}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="h-100 d-flex align-items-center justify-content-start gap-2">
                                <p className="my-0 w-100">{order.status}</p>
                            </div>
                        </td>
                        <td className="py-2 align-middle">
                            <div className="d-flex align-items-center justify-content-start gap-2">
                                <Price price={order.checkoutSession.amount} currencyCode="GBP" />
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </>;
}

import React from "react";
import "./price.scss";
export default function Price(props: { price: number, currencyCode: string, showFree?: boolean, classes?: string } = { price: 0, currencyCode: 'GBP', showFree: false, classes: '' }) {
  return (<>{
    props.price === 0 && props.showFree === true ? <span className={props.classes}>Free</span> :
      <span className={props.classes}>£{(props.price / 100).toFixed(2)}</span>
  }</>);
}



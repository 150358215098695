import { HTTP_CONSTANTS } from "../../constants/http-constants";
import CheckoutRequest, { CheckoutValidator } from "../../models/requests/CheckoutRequest";
import { Order } from "../../models/responses/CheckoutResponse";
import CheckoutResponse from "../../models/responses/CheckoutResponse";
import HttpService, { HttpError, ResponseCallback } from "../http/http-service";
import Cookies from "js-cookie";
import { CartItem } from "./cart-service";

function Checkout(
    request: CheckoutRequest,
    callback: ResponseCallback<CheckoutResponse>
): void {
    HttpService.post<CheckoutRequest, CheckoutResponse>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout`,
        CheckoutValidator.Build(CheckoutValidator.Sign(request)),
        (resp: CheckoutResponse) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function Complete3DS(
    request: { threeDSRef: string, threeDSMethodData: string },
    callback: ResponseCallback<CheckoutResponse>
): void {
    HttpService.post<{ threeDSRef: string, threeDSMethodData: string }, CheckoutResponse>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout/3ds/validate`,
        request,
        (resp: CheckoutResponse) => {
            if (resp.status === '3ds') {
                // we need to redo 3ds
                console.log('set', resp.threeDSRef)
                Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www', '') });
                // Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www.', '') });
                window.setTimeout(() => {
                    callback.success(resp);
                }, 100);
            } else {
                console.log('set', resp.threeDSRef)
                Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www', '') });
                // Cookies.set('3dsref', resp.threeDSRef, { domain: window.location.hostname.replace('www.', '') });
                window.setTimeout(() => {
                    callback.success(resp);
                }, 100);
            }
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function AuthTransaction(cres: string, callback: ResponseCallback<Order>): void {
    var threeDSRef = Cookies.get("3dsref");
    //var threeDSRef = localStorage.getItem('3dsref');
    if (threeDSRef === null || threeDSRef === undefined) { callback.error('No 3DS Ref'); return; }
    HttpService.post<{ threeDSRef: string, cres: string }, Order>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout/authorise`,
        { threeDSRef: threeDSRef, cres: cres },
        (resp: Order) => {
            console.log("REMOVE 3DS REF COOKIE");
            Cookies.remove('3dsref', { domain: window.location.hostname.replace('www', '') })
            // Cookies.remove('3dsref', { domain: window.location.hostname.replace('www.', '') })
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

function GetTransaction(id: string, callback: ResponseCallback<Order>): void {
    HttpService.get<Order>(
        `${HTTP_CONSTANTS.protocol}${HTTP_CONSTANTS.baseAddress}/checkout/order/${id}`,
        (resp: Order) => {
            callback.success(resp);
        },
        (error: HttpError) => {
            callback.error(error.error);
        }
    );
}

const shippingMatrix: any = {
    'UPS': {
        'BT': 1500,
        'IM': 2500,
        'KW': 2500,
        'HS': 2500,
        'IV': 2500,
        'ZE': 2500,
        'JE': 2500,
        'GY': 2500
    },
    'AXL': {
        'AB': 8500,
        'DD': 8500,
        'FK': 8500,
        'HS': 8500,
        'IV': 8500,
        'KW': 8500,
        'KY': 8500,
        'G52': 8500,
        'G53': 8500,
        'PA': 8500,
        'PH': 8500,
        'TD': 8500,
        'ZE': 8500,
        'BT': 10000,
        'IM': 10000,
        'JE': 10000,
        'GY': 10000,
    }
}

function GetShippingPrice(shippingZIP: string, cartItem: CartItem): number {
    shippingZIP = shippingZIP.toUpperCase();
    const companyList = shippingMatrix[cartItem.variant.postageProvider];
    if (companyList === undefined) return 0;    // no provider - will fall over serverside
    var shippingZip = Object.keys(companyList).find(zipStart => shippingZIP.startsWith(zipStart))
    if (shippingZip === undefined) return 0;    // no provider - will fall over serverside
    return companyList[shippingZip];
}

function GetOrderTotal(shippingZIP: string, cartItem: CartItem[]): number {
    if (cartItem.length === 0) return 0;
    const products = cartItem.map(ci => ci.variant.price * ci.quantity).reduce((p, c) => p + c);
    const shipping = cartItem.map(ci => GetShippingPrice(shippingZIP, ci)).reduce((p, c) => p + c);
    return products + shipping;
}

const CheckoutService = {
    Checkout,
    Complete3DS,
    AuthTransaction,
    GetShippingPrice,
    GetOrderTotal,
    GetTransaction
};

export default CheckoutService;
